.menu-tab-g{
  position: fixed;
  top: $header-height;
  background: $bg-cl-gray-7;
  max-height: $menu-tab-height;
  z-index: $z-index-menu-tab;
  button{
    color: $ft-cl-black;
  }
}

.record-tab-g{
  margin-left: -24px;
}

.record-search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .svg {
    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}


#pagingArea{
  padding-top: $header-height;
  .paging-wrapper {
    padding: 40px 0 14px 0;
    text-align: center;
  }
}

.main-content {
  +.flicking-pagination{
    position: absolute;
    top:20px;
    height: 10px;
  }

  .mission-content,.walk-content,.sleep-content,.weight-content{
    background: $bg-cl-gray-7;
    color: $basic-black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
    max-height: calc(100vh - ($header-height + $menu-tab-height ));
  }

  .page-title {
    text-align: center;
    font-size: 13px;
    padding-top: 50px;
  }

  .page-info {
     p {
       text-align: center;
     }
  }

  .total-distance {

    p{
      text-align: center;
      font-size:20px;
      margin-left: 70px;
      margin-top: -25px;
    }

    .animated-div{
      text-align: center;
      font-size:50px;
    }

    .animated-div-dot{
      text-align: center;
      font-size:50px;
      margin-left: 55px;
      margin-top : -50px;
    }
  }

  .last-data {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    .last-data-items {
      padding-top: 5px;
    }
  }
}


