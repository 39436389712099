#SleepView {
  .sleep-time{
    height: 137px;
    display: flex;
    flex-direction: column;

    .sleep-time-row{
      display: flex;
      place-self: flex-start;

      .sleep-time-graph-row{
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        .sleepMinMax {
          font-size: 10px;
        }
      }
    }

    .sleep-time-graph-row2{
      display: flex;
      .sleep-time-graph1-left{
        background-color: $primary-cyan01;
        height: 6px;
      }

      .sleep-time-graph1-right{
        background-color: $gray07;
        height: 6px;
      }
    }

    .sleep-time-row-item{
      display: flex;
      align-items: flex-end
    }

    .ft-50px{
      font-size: 50px;
    }
 }

  .sleep-score{
    height: 107px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sleep-score-row{
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .ft-14px{
      font-size: 14px;
      position: relative;
      top: -2px;;
    }

  }

  .sleep-goal{
    height: 75px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .sleep-goal-row{
      display: flex;
      justify-content: space-between;
    }
  }

  .sleepStageBtn-wrapper {
    button {
      border-radius: 100px;
      height: 24px;
      margin-right: 4px;
      padding: 2px 16px;
      line-height: 122%;
      font-size: 13px;
      font-weight: 400;
      background: rgba(255, 255, 255, 0.4);
      color: rgba(26, 26, 26, 0.8);

      &.on {
        background: $basic-white;
        color: $basic-black;
      }
    }
  }

  .grid-data {
    background-color: #F1F1F1;
    width: 100%;
    .row {
      height: 38px;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 0.5px solid #D1D1D1;
      .row-item {
        color: $ft-cl-caption;
      }
    }
    :last-child{
      border-bottom: none;
    }
  }

  .stage-inform {
    width: 100%;
    background-color: $bg-cl-gray-9;
    border-radius: 10px;
    .stage-inform-header{
      height: 46px;
    }
  }

  .bubble{
    box-sizing: border-box;
    position: relative;
    background: #F4F4F4;
    border: 1px solid #24CBAA;
    border-radius: 102.037px;
    width: 100%;
    margin: 0 auto;
    padding: 8px 24px;
    z-index: 0;
  }
  .bubble:after{
     content: '';
     position: absolute;
     border-style: solid;
     border-width: 0 8px 8px;
     border-color: #F4F4F4 transparent;
     display: block;
     width: 0;
     z-index: 2;
     top: -3.5px;
     //left: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
   }

  .bubble:before{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8.5px 8.5px;
    border-color: #24CBAA transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -4.5px;
    //left: 46.9%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .body-energy-year-inform {
    width: 100%;
    background-color: $bg-cl-gray-8;
    border-radius: 50px;
    .body-energy-year-inform-header{
      height: 38px;
    }
  }
}

