.refresh-area {
  position: absolute;
  display: inline-block;
  z-index: $z-index-refresh;
  background: inherit;
  height: fit-content;
}

.ptr-element .genericon {
  background: inherit;
  transition: all 0.25s ease;
  -webkit-transform: rotate(90deg);
  margin-top: -30px;
  height: fit-content;
}
.ptr-refresh .ptr-element .genericon {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  display: none;
  background: inherit;
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index:$z-index-refresh;
}
.ptr-loading .loading {
  display: block;
}

@-webkit-keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: inherit;
    opacity: 1;
  }
}

@keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    transform: translateY(0) scale(0.3);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: inherit;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  -webkit-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
  margin-top: -30px;
}

.refresh-w, .refresh-b{
  width: 30px;
  height: 30px;
}