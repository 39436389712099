.rothy-popup {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-popup;
    background-color:rgba(0,0,0,.3);

    .popup-body {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius:  16px 16px 0px 0px;

        >div:first-child {
            padding-top: 28px;
            padding-bottom: 28px;
            
            span {
                position: absolute;
                top:28px;
                right: 16px;
            }
        }
        
        .content {
            max-width: $screen-max-width;
            max-height: 80vh;
            margin: 0 auto;
        }
    }

    .web-popup-body{
        position: fixed;
        left: 0;
        right: 0;
        bottom: -3px;
        border-radius:  16px 16px 0px 0px;
    }

    .custom-select {
        display:flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        .light{
            .select-items {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 56px;
                padding-left: 24px;
                .icon{
                    margin-right: 16px;
                    .select-box-selected{
                        width: 14px;
                        height: 10px;
                    }
                }
            }
            .item-selected{
                background: $gray08;
                color: $primary-cyan01;
            }
        }
        .dark{
            .select-items {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 56px;
                padding-left: 24px;
                .icon{
                    margin-right: 16px;
                    .select-box-selected{
                        width: 14px;
                        height: 10px;
                    }
                }
            }
            .item-selected{
                background: $gray01;
                color: $primary-cyan01;
            }
        }
    }
}

//mui-toast popup start
.MuiAlert-root {
    display: block !important;
    background-color: #414141 !important;
    border-radius: 4px !important;

    position: fixed;
    bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    min-height: 62px;
    height: fit-content;

    font-size: 13px !important;
    font-family: 'SpoqaHanSansNeo-Regular' !important;
    z-index: $z-index-toast;
}

.toast-alert-detail {
    display: block !important;
    text-align: center !important;
    color: $ft-cl-white ;
    line-height: 22px;
    height: fit-content;
}
//mui-toast popup end

//modal confirm, alert start

.MuiBox-root {
    outline: none;
    min-height : 60px;
    background: #FFFFFF;
    border-radius: 12px;

    font-family: 'SpoqaHanSansNeo-Regular' !important;
    //top: calc(40%);
    //margin: 1.75rem auto !important;
    //-webkit-tap-highlight-color: transparent;
}

.modal-btn {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    position: relative;
    color: #317CF6;
    height: 100%;
    padding-top: 15px;
}

.confirm-btn {
    width: 50%;
}

.confirm-lbtn {
    border-right: 1px solid #e5e7eb;
}

.alert-btn {
    width: 100%;
}

.modal-body{
    font-family: $basic-font;
    font-size: 13px;
    text-align: center;
    padding: 20px;

    span{
        white-space: pre-line;
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 1.7;
        font-family: $basic-font;
        font-weight: $font-weight-r;
    }
}

.modal-footer{
    display: flex;
    height: 45px;
    line-height: 1;
    border-top: 1px solid #e5e7eb;
}
//modal confirm, alert end
