@include themeify() {
    button {
        @include button1-style;
        padding: 10px;
        border-radius: 4px;
        color: useVar("btn-ft-cl-outlined");

        &.primary {
            background-color: useVar("btn-bg-primary-enabled");
            color: useVar("btn-ft-cl");
            &:active {
                background-color: useVar("btn-bg-primary-pressed");
            }
            &:disabled {
                background-color: useVar("btn-bg-disabled");
                color: useVar("btn-ft-cl-disabled");
            }
        }
        &.black {
            background-color: useVar("btn-bg-black-enabled");
            color: useVar("btn-ft-cl");
            &:active {
                background-color: useVar("btn-bg-black-pressed");
            }
            &:disabled {
                background-color: useVar("btn-bg-disabled");
                color: useVar("btn-ft-cl-disabled");
            }
        }

        &.gray03 {
            background-color: useVar("btn-bg-gray03-enabled");
            color: useVar("btn-ft-cl");
            &:active {
                background-color: useVar("btn-bg-gray03-pressed");
            }
            &:disabled {
                background-color: useVar("btn-bg-disabled");
                color: useVar("btn-ft-cl-disabled");
            }
        }

        &.outlined {
            border: useVar("btn-bd-outlined");
            color: useVar("btn-ft-cl-outlined");
            &:disabled {
                color: useVar("btn-ft-cl-outlined-disabled");
            }
        }
    }
}

.button-wrapper {
    max-width: calc($screen-max-width - 48px);
    width: calc(100% - 48px);
    position: absolute;
    bottom: 0px;
    margin: 8px 24px 28px 24px;
    text-align: center;
}

.bottomButton {
    width: 100%;
}

.bottomButton-35p {
    width: 35%;
}

.bottomButton-63p {
    width: 63%;
}

.button-wrapper-bottom {
    margin-top: 20px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.round-button-wrapper {
    width: 100%;
    text-align: center;
    position: relative;
    .button-info{
        p {
            @include caption1-style;
            font-weight: $font-weight-l;
            background-color: #414141;
            border-radius: 3px;
            padding: 10px 12px 10px 12px;
            color: $basic-white;
            text-align: left;
        }
        width: 100%;
        position: absolute;
        top: -50px;
        z-index: 15;
        display: none;
    }
    .button-info:after
    {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 8px 5px 0 5px;
        border-color: #414141 transparent;
        bottom: -7px;
        left: 20%;
    }

    button{
        position: absolute;
        left: 0;
        width: 100%;
        border-radius: 100px;
        background-color: $basic-black;
        color: $basic-white;
        @include caption1-style;
        font-weight: $font-weight-l;

        &.off {
            background: #AAAAAA;
            color: #D1D1D1;
        }
    }
}