.custom-input {
  width: 100%;
  @mixin light-style {
    .custom-input-box{
      height: 70px;
      border-radius: 8px;
      background: $bg-cl-gray-9;
      color: $gray04;
      .icon-wrapper {
        flex-direction: column;
        justify-content: center;
        display: flex;
        margin-right: 18px;
        .click-area{
          width: 17px;
          height: 17px;
        }
      }
      input {
        background: $bg-cl-gray-9;
        color: $bg-cl-black;
        border: 0;
        width: 100%;
        outline: none;
      }
    }
    .icon{
      .expend-off{
        width: 16px;
        height: 16px;
      }
    }
  }
  .light {
    @include light-style;
    &_selected{
      @include light-style;
      .custom-input-box {
        color: $primary-cyan01;
        border: 1px solid;
      }
      .icon{
        .input-delete-l, .input-delete-d{
          width: 17px;
          height: 17px;
        }

      }
    }
    &_error{
      @include light-style;
      .custom-input-box {
        color: $point03;
        border: 1px solid;
      }
      .icon{
        .input-delete-l, input-delete-d{
          width: 17px;
          height: 17px;
        }

        .error-info{
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  @mixin  dark-style {
    .custom-input-box{
      height: 70px;
      border-radius: 8px;
      background: $bg-cl-gray-1;
      color: $gray04;
        .icon-wrapper {
          flex-direction: column;
          justify-content: center;
          display: flex;
          margin-right: 18px;
          .click-area{
            width: 17px;
            height: 17px;
          }
        }
      input {
        background: $bg-cl-gray-1;
        color: $bg-cl-white;
        border: 0;
        width: 100%;
        outline: none;
      }
    }

    .icon{
      .expend-off{
        width: 16px;
        height: 16px;
      }
    }
  }
  .dark {
    @include dark-style;
    &_selected{
      @include dark-style;
      .custom-input-box {
        color: $primary-cyan01;
        border: 1px solid;
      }
      .icon{
        .input-delete-l, .input-delete-d{
          width: 17px;
          height: 17px;
        }
      }
    }
    &_error{
      @include dark-style;
      .custom-input-box {
        color: $point03;
        border: 1px solid;
      }
      .icon{
        .input-delete-l, input-delete-d{
          width: 17px;
          height: 17px;
        }

        .error-info{
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.custom-input-one {
  width: calc(100%);
  border:none;
  border-right:0px;
  border-top:0px;
  boder-left:0px;
  boder-bottom:1px;
}

.custom-input-one::placeholder{
  color: #D1D1D1;
}