.walk-distance{
  .record-comment{
    white-space: pre-wrap;
  }
  .distance-info{
    position: relative;
    bottom: 0;
    left: 0;
    .distance-summary{
      position:absolute;
      bottom:0;
      margin-left:24px;
      .bubble{
        background: #FFFFFF;
        border: 1.61576px solid #24CBAA;
        border-radius: 93.8406px;
      }
    }
  }
}

.walk-score-description{
  white-space: pre-wrap;
}

.walk-score{
  .bubble{
    box-sizing: border-box;
    position: relative;
    background: #F4F4F4;
    border: 1px solid #24CBAA;
    border-radius: 102.037px;
    width: 100%;
    margin: 0 auto;
    z-index: 0;
  }
  .bubble:after
  {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8.5px 8.5px;
    border-color: #F4F4F4 transparent;
    display: block;
    width: 0;
    z-index: 2;
    top: -7.5px;
    left: 30%;
  }

  .bubble:before
  {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8.5px 8.5px;
    border-color: #24CBAA transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -9px;
    left: 30%;
  }
}

.walk-far, .calorie-burned{
  .toggle-box{
    position: relative;
    background: #F1F1F1;
    border-radius: 500px;
    width: fit-content;
    height: 25px;
    button {
      @include caption1-style;
      font-width: $font-weight-r;
      height: 25px;
      border-radius: 200px;
      text-align: center;
      padding: 2px 15px;

      &.on {
        @include caption1-style;
        font-weight: $font-weight-m;
        height: 25px;
        border-radius: 200px;
        text-align: center;
        background: #FFFFFF;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.calorie-burned, .walk-time{
  .title {
    white-space: pre-wrap;
  }
  .bubble{
    box-sizing: border-box;
    position: relative;
    background: $basic-white;
    border: 1px solid #24CBAA;
    border-radius: 100px;
    width: 100%;
    margin: 0 auto;
    z-index: 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .bubble:after
  {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 7px 7px;
    border-color: $basic-white transparent;
    display: block;
    width: 0;
    z-index: 2;
    top: -3px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bubble:before
  {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 7.5px 7.5px;
    border-color: #24CBAA transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -4px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.goal-input{
  .css-1smmpxc-MuiInputBase-root-MuiInput-root{
    align-items: baseline;
    input{
      @include h3-style;
      font-weight: $font-weight-m;
      color: $primary-cyan01;
    }
  }

  .css-1laqsz7-MuiInputAdornment-root{
    p {
      @include body2-style;
      font-weight: $font-weight-r;
      color: $basic-black;
    }
  }
}

.change-goal-popup {
  .goal-info {
    width: 100%;
    background-color: rgba(50, 52, 58, 0.05);
    border-radius: 10px;
    padding: 12px 16px 12px 16px;
    visibility: hidden;
    white-space: pre-wrap;
    @include caption1-style;
    font-weight: $font-weight-r;

    p {
      color: $gray04;
    }

    span {
      color: red;
    }
  }

  .popup-buttons {
    position: fixed;
    bottom: 28px;
    padding-right: 24px;

    .back-button {
      background-color: $basic-black;
      color: $basic-white;
      text-align: center;
      width: 30%;
      height: 56px;
      border-radius: 8px;

      &:disabled {
        background-color: $gray06;
      }
    }

    .change-button {
      background-color: $primary-cyan01;
      color: $basic-white;
      text-align: center;
      width: 60%;
      height: 56px;
      border-radius: 8px;
      margin-left: 8px;

      &:disabled {
        background-color: $gray06;
      }
    }
  }
}

.goal-recommend{
  .recommend-popup-button{
    position: fixed;
    bottom: 28px;
    background-color: $primary-cyan01;
    color: $basic-white;
    border-radius: 8px;
    height: 56px;
    width: 100%;
    text-align: center;
  }

  .custom-button{
    @include caption1-style;
    font-weight: $font-weight-l;
    width: 130px;
    height: 34px;
    border-radius: 100px;
    border: 0.6px solid $basic-black;
    text-align: center;
    padding: 8px 16px 8px 16px;
  }

  .popup-buttons {
    position: fixed;
    bottom: 28px;
    padding-right: 24px;

    .stay-button{
      background-color: $basic-black;
      color: $basic-white;
      text-align: center;
      width: 45%;
      height: 56px;
      border-radius: 8px;
    }

    .change-button{
      background-color: $primary-cyan01;
      color: $basic-white;
      text-align: center;
      width: 45%;
      height: 56px;
      border-radius: 8px;
      margin-left: 8px;
    }
  }

  .flicking-pagination {
    position: absolute;
    left: 0;
    bottom: 120px;
    width: 100%;
    text-align: left;
    z-index: 2;

    .flicking-pagination-bullet {
      width: 8px;
      height: 8px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      margin: 0 4px;
      font-size: 1rem;
      display: inline-block;
    }

    .flicking-pagination-bullet-active {
      background-color: black !important;
    }
  }
}
