@include themeify() {
    .bubble-speach {
        position: relative;
        max-width: calc($screen-max-width - 32px);
        width: fit-content;
        margin-right: 16px;
        margin-left: 16px;
        margin: 0 auto;
        padding: 8px 25px;
        border: 1px solid $primary_cyan01;
        border-radius: 3px;
        background-color: $basic-black;
        text-align: center;
        color: $basic-white;
        font-size: 13px;
        word-break: break-all;
        z-index: $z-index-bubble;

        ::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            background-color: transparent;
            border: 6px solid transparent;
            border-top-color: $primary-cyan01;
            border-bottom: 0;
            margin-left: -6px;
            margin-bottom: -6px;
        }

        ::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            background-color: transparent;
            border: 5px solid transparent;
            border-top-color: $basic-black;
            border-bottom: 0;
            margin-left: -5px;
            margin-bottom: -5px;
        }
    }

    body {
        -ms-overflow-style: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .content-main {
        width: calc(100% - 48px);
        margin: 38px 0px 28px 0px;
    }

    .flicking-pagination {
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 100%;
        text-align: center;
        z-index: 2;

        .flicking-pagination-bullet {
            width: 8px;
            height: 8px;
            cursor: pointer;
            background-color: useVar('flicking-bullet-bg-inactive');
            border-radius: 50%;
            margin: 0 4px;
            font-size: 1rem;
            display: inline-block;
        }

        .flicking-pagination-bullet-active {
            background-color: useVar('flicking-bullet-bg-active')  !important;
        }
    }
}

