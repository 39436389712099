#TermsDetail, #TermsMain{
  .main-content, .detail-content {
    height: calc(100vh - ($header-height));
    background: $basic-black;
    color: $basic-white;
    display: flex;
    flex-direction: column;

    .page-title {
      text-align: center;
      font-size: 13px;
      padding-top: 50px;
    }

    .page-info {
      p {
        text-align: center;
      }
    }

    .board {
      height: calc(100vh - ($header-height) - 80px);
      overflow-y: auto;
      line-height: 180%;
    }
  }

  .main-content-w {
    height: calc(100vh - ($header-height));
    background: $basic-white;
    color: $basic-black;
    display: flex;
    flex-direction: column;

    .page-title {
      text-align: center;
      font-size: 13px;
      padding-top: 50px;
    }

    .page-info {
      p {
        text-align: center;
      }
    }

    .board {
      height: calc(100vh - ($header-height) - 80px);
      overflow-y: auto;
      line-height: 180%;
    }
  }
}

#TermsMain{
  .main-content {
    margin: 0px 24px 0px 24px;
  }

  .title {
    width: calc(100% - 48px);
  }

  .all-agree {
    width: 100%;
    height: 56px;
    background: #333333;
    border-radius: 8px;
    margin-top: 48px;
    margin-bottom: 6px;

    padding-top: 13px;
    padding-left: 14px;

    .span{
      height: 100%;
    }
  }

  .terms-content {
    height: calc(100vh - 285px);
    overflow-y: auto;
  }

  .all-agree-item{
    display: inline-block;
    .icon {
      height: 20px;
    }
  }

  .agree-detail{
    padding-left: 16px;
    padding-right: 5px;
  }

  .all-agree-checkbox{
    position: relative;
    top: 3px;
    margin-right: 8px;
  }

  .detail-agree-item{
    display: inline-block;
    .icon {
      height: 17px;
    }
  }

  .detail-agree-checkbox{
    margin-right: 8px;
  }

  .read-btn{
    justify-content: space-between;
    line-height: 2;
    text-decoration: underline;
  }

  .termDescription{
    margin-top: 8px;
    margin-left: 36px;
    padding: 10px 12px 10px 12px;
    width: calc(100% - 36px);
    //height: ;
    background-color: $bg-cl-gray-2;
    border-radius: 8px;



    .row-header{
      vertical-align: top;
      width: 50px;
      display: inline-block;
      color: $ft-cl-hint;
    }

    .row-body{
      display: inline-block;
      color: $gray05;
    }
  }
}

#Signup {
  .main-content {
    height: calc(100vh - ($header-height));
    background: $basic-black;
    color: $basic-white;
    display: flex;
    flex-direction: column;
    margin: 0px 24px 0px 24px;

    .input-wrapper {
      margin-top: 12px;
      overflow: auto;
      max-height: calc(100vh - (220px + $header-height));
    }

    .input-item {
      margin-top: 12px;
      color: $gray02;
    }

  }
}

#HealthConnectGuide {
  .main-content {
    height: calc(100vh - ($header-height));
    background: $basic-black;
    color: $basic-white;
    display: flex;
    flex-direction: column;
    margin: 0px 24px 0px 24px;

    .input-wrapper {
      margin-top: 12px;
    }

    .input-item {
      margin-top: 12px;
      color: $gray02;
    }

  }
}