@include themeify() {

  #loading-page {
    position: absolute;
    top: calc(50% - 30px);
    left: 50%;
    transform: translate(-50%, -50%);
    .MuiBox-root {
      background-color:transparent !important;
    }
  }

  //deviders
  .border-bottom-gray {
    border-bottom: 1px solid $bg-cl-gray-7;
  }
  .border-top-gray {
    border-top: 1px solid $bg-cl-gray-7;
  }

  //displays
  .ds-none {
    display: none;
  }
  .flex {
    display: flex;
  }
  .flex-column{
    display: flex;
    flex-direction: column;
  }
  .flex-row-reverse{
    display: flex;
    flex-direction: row-reverse;
  }
  .flex-1 {
    flex: 1;
  }
  .inline-block {
    display: inline-block;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-flex-end {
    justify-content: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .flex-start {
    align-items: flex-start;
  }
  .flex-end {
       align-items: flex-end;
  }
  .align-baseline {
    align-items: baseline;
  }

  .align-content-center {
    align-content: center;
  }

  .justify-space-between {
    justify-content: space-between;
  }


  //text styles
  .text-center { text-align: center; }
  .text-right { text-align: right; }
  .text-left { text-align: left; }
  .text-underline { text-decoration: underline; }

  //width
  .w-30p { width: 30%; }
  .w-70p { width: 70%; }
  .w-90p { width: 90%;}
  .w-100p { width: 100%; max-width: $screen-max-width; }

  .w-2px { width: 2px;}
  .w-4px { width: 4px; }
  .w-5px { width: 5px; }
  .w-6px { width: 6px; }
  .w-7px { width: 7px; }
  .w-8px { width: 8px; }
  .w-10px { width: 10px; }
  .w-12px { width: 12px; }
  .w-14px { width: 14px; }
  .w-16px { width: 16px; }
  .w-17px { width: 17px; }
  .w-18px { width: 18px; }
  .w-19px { width: 19px; }
  .w-20px { width: 20px; }
  .w-24px { width: 24px; }
  .w-26px { width: 26px; }
  .w-27px { width: 27px; }
  .w-28px { width: 28px; }
  .w-30px { width: 30px; }
  .w-32px { width: 32px; }
  .w-34px { width: 34px; }
  .w-38px { width: 38px; }
  .w-40px { width: 40px; }
  .w-44px { width: 44px; }
  .w-48px { width: 48px; }
  .w-62px { width: 62px; }
  .w-130px { width: 130px; }
  .w-150px { width: 150px; }
  .w-154px { width: 154px; }
  .w-158px { width: 158px; }
  .w-184px { width: 184px; }
  .w-193px { width: 193px; }
  .w-200px { width: 200px; }
  .w-228px { width: 228px; }
  .w-256px { width: 256px; }
  .w-282px { width: 282px; }

  .wi-9682px { width: 96.82px; }

  .w-100p-48px {width: calc(100% - 48px); max-width: $screen-max-width;}

  //height
  .h-1px { height: 1px; }
  .h-2px { height: 2px; }
  .h-4px { height: 4px; }
  .h-5px { height: 5px; }
  .h-6px { height: 6px; }
  .h-8px { height: 8px; }
  .h-10px { height: 10px; }
  .h-12px { height: 12px; }
  .h-13px { height: 13px; }
  .h-14px { height: 14px; }
  .h-16px { height: 16px; }
  .h-17px { height: 17px; }
  .h-18px { height: 18px; }
  .h-19px { height: 19px; }
  .h-20px { height: 20px; }
  .h-22px { height: 22px; }
  .h-24px { height: 24px; }
  .h-26px { height: 26px; }
  .h-29px { height: 29px; }
  .h-32px { height: 32px; }
  .h-40px { height: 40px; }
  .h-41px { height: 41px; }
  .h-42px { height: 42px; }
  .h-48px { height: 48px; }
  .h-53px { height: 53px; }
  .h-56px { height: 56px; }
  .h-61px { height: 61px; }
  .h-64px { height: 64px; }
  .h-70px { height: 70px; }
  .h-72px { height: 72px; }
  .h-75px { height: 75px; }
  .h-84px { height: 84px; }
  .h-85px { height: 85px; }
  .h-86px { height: 86px; }
  .h-88px { height: 88px; }
  .h-90px { height: 90px; }
  .h-96px { height: 96px; }
  .h-100px { height: 100px; }
  .h-130px { height: 130px; }
  .h-141px { height: 141px; }
  .h-150px { height: 150px; }
  .h-200px { height: 200px; }
  .h-210px { height: 210px; }
  .h-300px { height: 300px; }
  .h-400px { height: 400px; }
  .h-312px { height: 312px; }
  .h-header { height: $header-height; }
  .h-fit-content { height: fit-content; }
  .h-100vh { height: 100vh;}
  .h-80vh { height: 80vh;}
  .h-70vh { height: 70vh;}
  .h-65vh { height: 65vh;}
  .h-60vh { height: 60vh;}

  .hi-14627px { height: 146.27px; }

  //padding
  .padding-0{ padding: 0; }

  .pt-2px{ padding-top: 2px; }
  .pt-3px{ padding-top: 3px; }
  .pt-5px{ padding-top: 5px; }
  .pt-7px{ padding-top: 7px; }
  .pt-8px{ padding-top: 8px; }
  .pt-11px{ padding-top: 11px; }
  .pt-12px{ padding-top: 12px; }
  .pt-14px{ padding-top: 14px; }
  .pt-16px { padding-top: 16px; }
  .pt-17px { padding-top: 17px; }
  .pt-19px { padding-top: 19px; }
  .pt-20px { padding-top: 20px; }
  .pt-21px { padding-top: 21px; }
  .pt-22px { padding-top: 22px; }
  .pt-23px { padding-top: 23px; }
  .pt-24px { padding-top: 24px; }
  .pt-26px { padding-top: 26px; }
  .pt-28px { padding-top: 28px; }
  .pt-55px { padding-top: 55px; }
  .pt-56px { padding-top: 56px; }
  .pt-60px { padding-top: 60px; }

  .pr-2px {padding-right: 2px;}
  .pr-4px {padding-right: 4px;}
  .pr-7px {padding-right: 7px;}
  .pr-10px {padding-right: 10px;}
  .pr-14px {padding-right: 14px;}
  .pr-22px { padding-right: 22px; }

  .pb-7px { padding-bottom: 7px; }
  .pb-8px { padding-bottom: 8px; }
  .pb-12px { padding-bottom: 12px; }
  .pb-13px { padding-bottom: 13px; }
  .pb-14px { padding-bottom: 14px; }
  .pb-16px { padding-bottom: 16px; }
  .pb-24px { padding-bottom: 24px; }
  .pb-40px { padding-bottom: 40px; }
  .pb-60px { padding-bottom: 60px; }
  .pb-62px { padding-bottom: 62px; }
  .pb-30p { padding-bottom: 30%; }

  .pl-2px{ padding-left: 2px; }
  .pl-5px{ padding-left: 5px; }
  .pl-7px{ padding-left: 7px; }
  .pl-8px{ padding-left: 8px; }
  .pl-14px{ padding-left: 14px; }
  .pl-16px{ padding-left: 16px; }
  .pl-18px{ padding-left: 18px; }
  .pl-17px{ padding-left: 17px; }
  .pl-22px{ padding-left: 22px; }

  .ptpb-22px {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .ptpb-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .prpl-24px {
    padding-left: 24px;
    padding-right: 24px;
  }
  .prpl-18px {
    padding-left: 18px;
    padding-right: 18px;
  }

  .prpl-4px {
    padding-left: 18px;
    padding-right: 18px;
  }

  .prpl-4px {
    padding-left: 18px;
    padding-right: 18px;
  }

  .p-screen-space {
    padding-right: $screen-space;
    padding-left: $screen-space;
  }

  //margin
  .m-24px{ margin: 24px; }

  .ml-2px {margin-left: 2px;}
  .ml-3px {margin-left: 3px;}
  .ml-4px {margin-left: 4px;}
  .ml-5px {margin-left: 5px;}
  .ml-6px {margin-left: 6px;}
  .ml-7px {margin-left: 7px;}
  .ml-8px {margin-left: 8px;}
  .ml-10px {margin-left: 10px;}
  .ml-12px {margin-left: 12px;}
  .ml-16px {margin-left: 16px;}
  .ml-18px {margin-left: 18px;}
  .ml-24px {margin-left: 24px;}
  .ml-65px {margin-left: 65px;}
  .ml-90px {margin-left: 90px;}
  .ml-25p {margin-left: 25%;}

  .mr-3px {margin-right: 3px;}
  .mr-4px {margin-right: 4px;}
  .mr-8px {margin-right: 8px;}
  .mr-10px {margin-right: 10px;}
  .mr-12px {margin-right: 12px;}
  .mr-16px {margin-right: 16px;}
  .mr-18px {margin-right: 18px;}
  .mr-22px {margin-right: 22px;}
  .mr-24px {margin-right: 24px;}
  .mr-25px {margin-right: 25px;}
  .mr-39px {margin-right: 39px;}

  .mt-2px {margin-top: 2px;}
  .mt-3px {margin-top: 3px;}
  .mt-4px {margin-top: 4px;}
  .mt-5px {margin-top: 5px;}
  .mt-6px {margin-top: 6px;}
  .mt-7px {margin-top: 7px;}
  .mt-8px {margin-top: 8px;}
  .mt-9px {margin-top: 9px;}
  .mt-10px {margin-top: 10px;}
  .mt-11px {margin-top: 11px;}
  .mt-12px {margin-top: 12px;}
  .mt-13px {margin-top: 13px;}
  .mt-14px {margin-top: 14px;}
  .mt-15px {margin-top: 15px;}
  .mt-16px {margin-top: 16px;}
  .mt-18px {margin-top: 18px;}
  .mt-19px {margin-top: 19px;}
  .mt-20px {margin-top: 20px;}
  .mt-22px {margin-top: 22px;}
  .mt-24px {margin-top: 24px;}
  .mt-25px {margin-top: 25px;}
  .mt-26px {margin-top: 26px;}
  .mt-28px {margin-top: 28px;}
  .mt-30px {margin-top: 30px;}
  .mt-32px {margin-top: 32px;}
  .mt-34px {margin-top: 34px;}
  .mt-35px {margin-top: 35px;}
  .mt-36px {margin-top: 36px;}
  .mt-38px {margin-top: 38px;}
  .mt-39px {margin-top: 39px;}
  .mt-40px {margin-top: 40px;}
  .mt-43px {margin-top: 43px;}
  .mt-48px {margin-top: 48px;}
  .mt-50px {margin-top: 50px;}
  .mt-56px {margin-top: 56px;}
  .mt-60px {margin-top: 60px;}
  .mt-63px {margin-top: 63px;}
  .mt-74px {margin-top: 74px;}
  .mt-84px {margin-top: 84px;}
  .mt-88px {margin-top: 88px;}
  .mt-96px {margin-top: 96px;}
  .mt-100px {margin-top: 100px;}
  .mt-210px {margin-top: 210px;}
  .mt-50p {margin-top: 50%;}

  .mb-2px{margin-bottom: 2px;}
  .mb-4px{margin-bottom: 4px;}
  .mb-8px{margin-bottom: 8px;}
  .mb-12px{margin-bottom: 12px;}
  .mb-16px{margin-bottom: 16px;}
  .mb-18px{margin-bottom: 18px;}
  .mb-20px{margin-bottom: 20px;}
  .mb-22px{margin-bottom: 22px;}
  .mb-24px{margin-bottom: 24px;}
  .mb-32px{margin-bottom: 32px;}
  .mb-42px{margin-bottom: 42px;}
  .mb-47px{margin-bottom: 47px;}
  .mb-52px {margin-bottom: 52px;}
  .mb-60px {margin-bottom: 60px;}
  .mb-100px {margin-bottom: 100px;}

  .mrml-24px {
    margin-right: 24px;
    margin-left: 24px;
  }

  .reverse-mrml-24px {
    margin-right: -24px;
    margin-left: -24px;
  }

  .ml-auto{
    margin-left: auto;
  }

  #scale-bg-l {
    position: relative;
    align-items: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .position-absolute {
    position: absolute;
  }
  .position-relative {
    position: relative;
  }

  .opacity-7 {
    opacity: 0.7;
  }

  .white-space-wrap{
    white-space: pre-wrap;
  }
}