.card-content {
  width: 100%;
  height: fit-content;
  background-color: $basic-white;
  border-radius: 8px;
}

.comment-box {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $bg-cl-gray-8;
  border-radius: 6px;
  white-space: pre-wrap;

  p{
    @include caption1-style;
    font-weight: $font-weight-r;
    color: $gray03;
  }
  span{
    @include caption1-style;
    font-weight: $font-weight-m;
    color: $primary-cyan01;
  }
}

.record-box {
  width: 100%;
  height: 74px;
  background-color: $bg-cl-gray-8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info-box{
  width: 100%;
  height: fit-content;
  background-color: $primary-cyan04;
  border-radius: 8px;
  padding: 11px 15px 11px 15px;
  display: flex;
  flex-direction: column;
  .date{
    color: $basic-black;
  }
  .description{
    color: $primary-cyan02;
  }
}

.health-record-btn{
  border-radius: 100px;
  border: 0.6px solid $gray04;
  width: fit-content;
  text-align: center;
  height: 36px;
  margin: auto;

  p{
    padding: 8px 16px 8px 16px;
    margin-bottom: 1px;
  }
}

.health-record-toast{
  margin-left: -48px;
  white-space: pre-wrap;
}

.auth-contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - (56px + 80px));
  overflow: scroll;
  position: absolute;
  top: $header-height;
  .certification-type {
    min-width: 98px;
    width: calc((100% - 18px) /3);
    height: 86px;
    background-color: $gray09;
    border-radius: 8px;
    color: $basic-black;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px 15px 0px;

    p {
      martin-top: 5px;
    }
  }
  .certification-type-selected{
    border: 2px solid $primary-cyan01;
  }

  .health-terms {
    background-color: #fafafa;
    width: 100%;
    height: fit-content;
    margin-top: 32px;
    padding: 24px 24px 24px 24px;

    .terms-all-agree {
      width: 100%;
      height: 56px;
      background-color: $gray08;
      display: flex;
      align-items: center;
      padding-left: 14px;
      border-radius: 8px;
    }

    .agree-detail {
      padding-left: 16px;
      padding-right: 5px;
    }

    .detail-agree-item {
      display: inline-block;

      .icon {
        height: 17px;
      }
    }

    .detail-agree-checkbox {
      margin-right: 8px;
    }

    .read-btn {
      justify-content: space-between;
      line-height: 2;
      text-decoration: underline;
    }
  }
}

.health-terms-btn-block{
  width: 100%;
  height: 56px;
  text-align: center;
  background-color: $gray04;
  color: $gray06;
  position: fixed;
  bottom: 0;
  padding: 20px;
}

.health-terms-btn{
  width: 100%;
  height: 56px;
  text-align: center;
  background-color: $primary-cyan01;
  color: $basic-white;
  position: fixed;
  bottom: 0;
  padding: 20px;
}

.auth-info-box{
  width: 100%;
  height: fit-content;
  padding: 20px 16px 20px 16px;
  magin-bottom: 20px;
  background-color: $gray09;
  border-radius: 8px;
}