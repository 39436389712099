@use "src/assets/scss/typo";
@include themeify() {
    .notification-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 18px;
        padding-top: 18px;
        width: 100%;
    }

    .notification-item-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .icon {
        .noti-point, .noti-one2one, .noti-mission, .noti-etc{
            width: 24px;
            height: 24px;
            margin: 22px 8px 4px 2px;
        }

        .new-noti-point, .new-noti-one2one, .new-noti-mission, .new-noti-etc{
            margin-right: 8px;
            margin-top: 18px;
            margin-left: -4px;
            width: 30px;
            height: 30px;
        }
    }
}