//Fonts
$basic-font: "SpoqaHanSansNeo";

//FontColors
$ft-cl-white: #FFFFFF;
$ft-cl-title: #212121;
$ft-cl-cotent: #212121;
$ft-cl-subtitle-1: #1A1A1A;
$ft-cl-subtitle-2: #616161;
$ft-cl-caption: #616161;
$ft-cl-hint: #AAAAAA;
$ft-cl-disabled: #D1D1D1;
$ft-cl-black: #4E4E4E;
$ft-cl-red: #FF0000;

.ft-cl-white { color: $ft-cl-white; }
.ft-cl-title { color: $ft-cl-title; }
.ft-cl-cotent { color: $ft-cl-cotent; }
.ft-cl-subtitle { color: $ft-cl-subtitle-1; }
.ft-cl-subtitle { color: $ft-cl-subtitle-2; }
.ft-cl-caption { color: $ft-cl-caption; }
.ft-cl-hint { color: $ft-cl-hint; }
.ft-cl-disabled { color: $ft-cl-disabled; }
.ft-cl-black { color: $ft-cl-black; }
.ft-cl-red { color: $ft-cl-red; font-size: 10px }

//FontSize
$font-weight-l:300;
$font-weight-r:400;
$font-weight-m:500;
$font-weight-b:700;


@mixin h0-style {
    font-size: 94px;
    letter-spacing: -0.5px;
    line-height: 120px;
    font-family: $basic-font;
}
.h0 {
    &_r {
        @include h0-style;
        font-weight: $font-weight-r;
    }
    &_m {
        @include h0-style;
        font-weight: $font-weight-m;
    }
    &_l {
        @include h0-style;
        font-weight: $font-weight-l;
    }
}

@mixin h1-style {
    font-size: 64px;
    letter-spacing: -0.5px;
    line-height: 1.2;
    font-family: $basic-font;
}
h1 {
    @include h1-style;
    font-weight: $font-weight-r;
    &._m { 
        @include h1-style;
        font-weight: $font-weight-m;
    }
}
.h1  {
    &_r { 
        @include h1-style;
        font-weight: $font-weight-r;
    }
    &_m {
        @include h1-style;
        font-weight: $font-weight-m;
    }
}

@mixin h2-style {
    font-size: 48px;
    letter-spacing: -0.3px;
    line-height: 1.4;
    font-family: $basic-font;
}
h2 {
    @include h2-style;
    font-weight: $font-weight-r;
    &._m { 
        font-weight: $font-weight-m;
    }
}
.h2  {
    &_r { 
        @include h2-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include h2-style;
        font-weight: $font-weight-m;
    }
    &_l{
        @include h2-style;
        font-weight: $font-weight-l;
    }
}

@mixin h3-style {
    font-size: 40px;
    letter-spacing: -0.3px;
    line-height: 1.6;
    font-family: $basic-font;
}
h3 {
    @include h3-style;
    font-weight: $font-weight-r;
    &._m { 
        font-weight: $font-weight-m;
    }
}
.h3  {
    &_r {
        @include h3-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        font-weight: $font-weight-m;
    }
}

@mixin h4-style {
    font-size: 32px;
    letter-spacing: -0.3px;
    line-height: 1.4;
    font-family: $basic-font;
}
h4 {
    @include h4-style;
    font-weight: $font-weight-r;
    &._m { 
        font-weight: $font-weight-m;
    }
}
.h4  {
    &_r { 
        @include h4-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include h4-style;
        font-weight: $font-weight-m;
    }
    &_l {
        @include h4-style;
        font-weight: $font-weight-l;
    }
    &_b {
        @include h4-style;
        font-weight: $font-weight-b;
    }
}

@mixin h5-style {
    font-size: 24px;
    letter-spacing: -0.3px;
    line-height: 1.4;
    font-family: $basic-font;
}
h5 {
    @include h5-style;
    font-weight: $font-weight-r;
    &._m { 
        font-weight: $font-weight-m;
    }
}
.h5  {
    &_r { 
        @include h5-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include h5-style;
        font-weight: $font-weight-m;
    }
    &_l {
         @include h5-style;
         font-weight: $font-weight-l;
     }
}


@mixin h6-style {
    font-size: 20px;
    letter-spacing: -0.3px;
    line-height: 1.4;
    font-family: $basic-font;
}
h6 {
    @include h6-style;
    font-weight: $font-weight-r;
    &._m { 
        font-weight: $font-weight-m;
    }
}
.h6  {
    &_r { 
        @include h6-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include h6-style;
        font-weight: $font-weight-m;
    }
    &_b {
        @include h6-style;
        font-weight: $font-weight-b;
    }
}

@mixin subtitle1-style {
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 1.7;
    font-family: $basic-font;
}
.s_title_1 {
    &_r { 
        @include subtitle1-style;
        font-weight: $font-weight-r;
    }
    &_m {
        @include subtitle1-style;
        font-weight: $font-weight-m;
    }
    &_l {
        @include subtitle1-style;
        font-weight: $font-weight-l;
    }
}

@mixin subtitle2-style {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 1.6;
    font-family: $basic-font;
}
.s_title_2 {
    &_l {
        @include subtitle2-style;
        font-weight: $font-weight-l;
    }
    &_r { 
        @include subtitle2-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include subtitle2-style;
        font-weight: $font-weight-m;
    }
    &_l {
        @include subtitle2-style;
        font-weight: $font-weight-l;
    }
}

@mixin body1-style {
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 1.6;
    font-family: $basic-font;
}
.b1 {
    &_l {
        @include body1-style;
        font-weight: $font-weight-l;
    }
    &_r { 
        @include body1-style;
        font-weight: $font-weight-r;
    }
    &_m {
        @include body1-style;
        font-weight: $font-weight-m;
    }
}

@mixin body2-style {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 1.7;
    font-family: $basic-font;
}
.b2 {
    &_l {
        @include body2-style;
        font-weight: $font-weight-l;
    }
    &_r { 
        @include body2-style;
        font-weight: $font-weight-r;
    }
    &_m {
        @include body2-style;
        font-weight: $font-weight-m;
    }
}

@mixin caption1-style {
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 1.6;
    font-family: $basic-font;
}
.c1 {
    &_r { 
        @include caption1-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include caption1-style;
        font-weight: $font-weight-m;
    }
    &_l {
        @include caption1-style;
        font-weight: $font-weight-l;
    }
}


@mixin caption2-style {
    font-size: 10px;
    letter-spacing: -0.1px;
    line-height: 1.6;
    font-family: $basic-font;
}
.c2 {
    &_r {
        @include caption2-style;
        font-weight: $font-weight-r;
    }
    &_m {
        @include caption2-style;
        font-weight: $font-weight-m;
    }
    &_l {
        @include caption2-style;
        font-weight: $font-weight-l;
    }
}

@mixin button1-style {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.6;
}
.btn1 {
    &_r {
        @include button1-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include button1-style;
        font-weight: $font-weight-m;
    }
}

@mixin button2-style {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1.6;
    font-family: $basic-font;
}
.btn2 {
    &_r {
        @include button2-style;
        font-weight: $font-weight-r;
    }
    &_m { 
        @include button2-style;
        font-weight: $font-weight-m;
    }
}

//FontFace
@font-face {
    font-family: "SpoqaHanSansNeo";
    src: url('../font/SpoqaHanSansNeo-Light.woff') format('woff'),
         url('../font/SpoqaHanSansNeo-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "SpoqaHanSansNeo";
    src: url('../font/SpoqaHanSansNeo-Regular.woff') format('woff'),
         url('../font/SpoqaHanSansNeo-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "SpoqaHanSansNeo";
    src: url('../font/SpoqaHanSansNeo-Medium.woff') format('woff'),
         url('../font/SpoqaHanSansNeo-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}