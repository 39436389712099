#Onboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: $screen-max-width;
    height: 100vh;
    text-align: center;

    .lottie-image {
        width: 100%;
        max-width: 375px;
        max-height: 312px;
    }

    .nextBtn {
        color: $basic-white;
    }

    .skip-btn {
        color: $gray06;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        letter-spacing: -0.1px;
        text-decoration-line: underline;

        text-align: right;
        text-decoration: underline;
        height: $header-height;
        padding: 19px $screen-space 31px $screen-space;
    }
}

.login-wrapper {
    display: block;
}

.sns-wrapper {
    .sns-icons {
        margin-top: 16px;
        border-bottom: 0.6px solid #D1D1D1;
        padding-bottom: 26px;
        justify-content: center;
        .sns-icon {
            margin-right: 16px;
        }
        :last-child {
            margin-right: 0px;
        }

        .sns-icon-left{
            align-items: start;
        }

        .sns-icon-center{
            align-items: center;
        }

        .sns-icon-right{
            align-items: end;
        }
    }
}

.email-wrapper {
    margin-top: 24px;
    .login-selected-email {
        position: absolute;
        top: 51%;
        justify-items: end;
        right: 20px;
    }
}


.signup-wrapper {
    margin-top: 35px;
    .signup-item {
        display: inline-block;
        margin-right: 8px;
        :last-child {
            //margin-right: 0px;
        }
    }

}

#Email-login {
    .main-content {
        height: calc(100vh - ($header-height));
        background: $basic-black;
        color: $basic-white;
        display: flex;
        flex-direction: column;
        margin: 0px 24px 0px 24px;

        .input-wrapper {
            margin-top: 12px;
        }

        .input-item {
            margin-top: 12px;
            color: $gray02;
        }

    }
}

#NoSignUpEmail, #SnsSignUpEmail {
    .main-content {
        height: calc(100vh - ($header-height));
        background: $basic-black;
        color: $basic-white;
        display: flex;
        flex-direction: column;
        margin: 0px 24px 0px 24px;

        .input-wrapper {
            margin-top: 12px;
        }

        .input-item {
            margin-top: 12px;
            color: $gray02;
        }

        .box {
            width: 100%;
            height: 56px;
            background: #333333;
            border-radius: 8px;
            margin-top: 48px;
            margin-bottom: 6px;

            padding-top: 15px;

            color: #AAAAAA;

            text-align: center;

        }

    }
}