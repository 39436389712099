#healthReportView{
  .health-report-header{
    position: relative;

    .report-add{
      position: absolute;
      top: 40px;
      right: 10px;
    }

  }

  .no-list{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 56px - $header-height);
    max-height: calc(100vh - 56px - $header-height);
    justify-content: center;
  }

  .report-list{
    overflow: scroll;
    max-height: calc(100vh - $header-height);
  }

  .bottom-btn{
    width: 100%;
    height: 56px;
    text-align: center;
    background-color: $primary-cyan01;
    color: $basic-white;
    position: absolute;
    bottom: 0;
    padding: 20px;
  }

  .warning-space{
    width: 100%;
    height: fit-content;
    padding: 20px;
    background: rgba(237, 173, 78, 0.15);
    border-radius: 8px;
  }

  .danger-space{
    width: 100%;
    height: fit-content;
    padding: 20px;
    background: rgba(240, 95, 95, 0.1);
    border-radius: 8px;
  }

}

#healthReportDetail{
  .health-report-header {
    position: relative;
  }
  .health-information{
    width: 100%;
    height: fit-content;
    padding: 13px 18px;
    background: rgba(244, 244, 244, 1);
    border-radius: 8px;
  }

  .detail-list{
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    overflow: scroll;
    max-height: calc(100vh - $header-height);
  }
}

#HealthAuthConfirm{
  .contents {
    position: absolute;
    top: $header-height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - (56px + 80px));
    overflow: scroll;
  }
}