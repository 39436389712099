#WeightDetail {
  margin: 0px 24px 0px 24px;
  padding: 55px 0px 60px 0px;

  .weight-detail-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .weight-detail-count-bar {
    margin-top: 26px;
    width: 100%;
    height: 10px;
    background-color: rgba(26, 26, 26, 0.05);
    position: relative;
  }

  .weight-detail-count-text {
    min-width: 51px;
    max-width: 100%;
    position: absolute;
    margin-top: -20px;
    text-align: end;
  }

  .weight-detail-count-content {
    position: absolute;
    height: 10px;
  }
}

#WeightAmount, #WeightHealthy, #WeightCount {
  .weight-record-title{
    font-size: 18px;
    line-height: 26px;
    font-family: $basic-font;
    font-weight: $font-weight-m;
    margin-top: 40px;
    padding-bottom: 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .weight-amount-button {
    display: flex;
    flex-wrap: wrap;
    max-width: 83%;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  button {
    border-radius: 100px;
    height: 24px;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 3px 12px;
    line-height: 18px;
    font-size: 12px;
    font-weight: $font-weight-r;
    background: rgba(255, 255, 255, 0.4);
    color: rgba(26, 26, 26, 0.8);

    &.on {
      font-weight: $font-weight-m;
      background: $basic-white;
      color: $basic-black;
    }
  }

  .weight-amount-avg{
    display: flex;
    align-items: center;
    border-radius: 50px;
    height: 26px;
    color: #ffffff;
    margin-left: 4px;
    margin-bottom: 11px;
    padding: 0px 10px;
    font-size: 14px;
    line-height: 17px;

    &.good {
      background: $primary-cyan01;
    }
    &.etc {
      background: $point07;
    }
  }

  .weight-grid{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;
    margin-bottom: 10px;

    div{
      display: flex;
      text-align: center;
      justify-content: center;
      color: $gray04;
    }
  }

  .grid-content-week {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 4px;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray06_1;
        border-radius: 5px;
        height: 52px;
        padding: 4px;

        &.today {
          background-color: $gray07;
          color: $gray05;
          border: 1px solid $gray03;
        }

        &.on {
          height: 64px;
          background-color: $primary-cyan01;
          color: $basic-white;
        }

        &.future {
          background-color: $gray07;
          color: $gray05;
          border: 1px solid $gray06_1;
        }
      }
  }


  .grid-content-month {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;

    >div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: $gray06_1;
      border-radius: 5px;
      height: 44px;
      padding: 4px;

      &.past {
        background-color: $gray07;
      }

      &.today {
        background-color: $gray07;
        border: 1px solid $gray03;
      }

      &.on {
        background-color: $primary-cyan01;

        >div{
          color: $basic-white;
        }
      }

      &.future {
        background-color: $gray07;
        color: $gray05;
        border: 1px solid $gray06_1;
      }
    }

  }

  .bubble{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    top: 360px;
    background: #F4F4F4;
    border: 1px solid #24CBAA;
    border-radius: 102.037px;
    width: 110%;
    height: 34px;
    margin: 0 auto;
    z-index: 0;
    text-align: center;
  }
  .bubble:after{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: #F4F4F4 transparent;
    display: block;
    width: 0;
    z-index: 2;
    top: -3.5px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .bubble:before{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8.5px 8.5px;
    border-color: #24CBAA transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -4.5px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .semicircle {
    position: absolute;
    &.zero{
      width: 28px;
      height: 14px;
      border-top: 0.5px solid #4E4E4E;
      border-left: 0.5px solid #4E4E4E;
      border-right: 0.5px solid #4E4E4E;
      border-radius: 14px 14px 0px 0px;
    }
    &.one {
      width: 48px;
      height: 24px;
      background: rgba(36, 203, 170, 0.5);
      border-radius: 24px 24px 0px 0px;
    }
    &.two {
      width: 68px;
      height: 34px;
      background: rgba(36, 203, 170, 0.5);
      border-radius: 34px 34px 0px 0px;
    }
    &.three {
      width: 84px;
      height: 42px;
      background: rgba(36, 203, 170, 0.5);
      border-radius: 42px 42px 0px 0px;
    }
  }

  .count-week{
    .y-ticks{
      position: absolute;
      top: 167px;
      left: 2px;
      line-height: 2;
    }
    .line{
      position: absolute;
      top: 185px;
      width: 110%;
      border: 0.5px dashed #AAAAAA;
    }
  }

  .count-month{
    .y-ticks{
      position: absolute;
      top: 6px;
      text-align: end;
      line-height: 3.8;
    }
    .line{
      position: absolute;
      top: 178px;
      left: 31px;
      width: 96%;
      border: 0.5px dashed #AAAAAA;
    }
  }

  .count-year{
    display: flex;
    position: absolute;
    left: 25px;

    >div{
      margin-left: 10px;
    }

    .month{
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .count-div{
    width: 14px;
    height: 14px;
    border-radius: 2px;
    margin-top: -2px;
    background: rgba(26, 26, 26, 0.03);

    &.before{
      background-color: $gray07;
    }

    &.one-time{
      background: rgba(36, 203, 170, 0.2);
    }

    &.two-time{
      background: rgba(36, 203, 170, 0.5);
    }

    &.three-time{
      background: rgba(36, 203, 170, 0.8);
    }

    &.today {
      background-color: $gray07;
      border: 1px solid $gray03;
      transform: rotate(0);
    }

    &.future{
      background-color: $gray07;
      border: 1px solid rgba(26, 26, 26, 0.03)
    }
  }

  .count-described{
    margin-top: -4px;
    margin-left: 6px;
    color: $gray04;
  }
}

.focus-select {
  .scroll-picker{
    position: relative;
    width: 100%;
    height: 250px;

    .scroll-picker-selected{
      background-color: $bg-cl-gray-6;
      position: absolute;
      top: 52px;
      width: 100%;
      height: 44px;
      z-index: 300;
      opacity: 0.2;
    }
  }
  .swiper {
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0px;
  }

  .swiper-wrapper {
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray03;
    }
    .swiper-slide-active{
      color: $basic-black;
    }
  }

  .popup-buttons {
    position: absolute;
    bottom: 0px;
    .confirm-button {
      background-color: $primary-cyan01;
      color: $basic-white;
      text-align: center;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      margin-bottom: 24px;

      &:disabled {
        background-color: $gray06;
      }
    }
  }
}
