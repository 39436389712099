@include themeify() {
    #rootBg {
        background-color: #E8E8E8;
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top : 0;
        z-index: -2;

    }

    #rootBg-black {
        background-color:#1A1A1A;
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top : 0;
        z-index: -1;
    }

    #rootBg-white {
        background-color:#FFFFFF;
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top : 0;
        z-index: -1;
    }

    #root {
        background-color: inherit;
        color: useVar("ft-cl");

        #contentBody {
            max-width: $screen-max-width;
            margin: 0 auto;
            font-family: $basic-font;

            .content {
                padding: 32px;
            }
        }
    }

    .header {
        position: fixed;
        width: 100%;
        max-width: $screen-max-width;
        font-size: 16px;
        font-weight: 600;
        top: 0;
        height: $header-height;
        line-height: calc($header-height / 2);
        text-align: center;
        z-index: $z-index-header;
        background-color: inherit;
        padding: 15px;

        .svg {
            display: inline;
            position: absolute;
            top: 0;
            padding: $screen-space;

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }

            .menu, .menu-w, .menu-b {
                width: 24px;
                height: 24px;
            }

            .prev, .prev-w, .prev-b  {
                width: 8px;
                height: 17px;
            }

            .close {
                width: 24px;
                height: 24px;
            }

            .close-small{
                width: 16px;
                height: 16px;
            }

        }
    }

    .walk-card-header {
        .svg {
            display: inline;
            top: 0;

            .share {
                width: 16px;
                height: 16px;
            }
        }
    }

    .walk-bottom-layout {
        padding-bottom: 10%;
    }

    .daily-card-content {
        width: 284px;
        background-color: $basic-white;
        margin-top: 14px;
        margin-right: 10px;
        padding: 22px 22px 0 22px;
        border-radius: 5px;
    }

    .guide {
        z-index: $z-index-guide;
        height: 60px;
        text-align: center;
        background-color: $gray02;
        color: $basic-white;
        border: 0.5px solid $basic-white;
        font-size: 13px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .guide-content {
            display: inline-flex;
            margin-top: 22px;
            text-align: center;

            p {
                margin-top: 2px;
            }
        }
    }

    .no-content-wrapper {
        width: 100%;
        height: calc(100vh - $header-height - $menu-tab-height);
        display: inline-flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}

.header-spacer {
    height: $header-height;
}

.header-tab-spacer {
    height: $header-height + $menu-tab-height;
}

.gray-bar {
    width: 100%;
    height: 8px;

    background: rgba(26, 26, 26, 0.03);
}

.black-bar {
    width: 100%;
    height: 1px;

    background: #212121;
}

.gray-bar-thin {
    width: 100%;
    height: 0.5px;

    background: rgba(0, 0, 0, 0.1);
}