/******* colors *******/
$basic-black:#1A1A1A;
$basic-white:#FFFFFF;

$primary-cyan01:#24CBAA;
$primary-cyan02:#11B998;
$primary-cyan03:#8FE2D2;
$primary-cyan04:#EAF5F4;
$primary-cyan05:#ADE6DF;

$secondary01:#43340E;
$secondary02:#FFD976;

$point01:#4586F4;
$point02:#EDAD4E;
$point03:#F05F5F;
$point04:#6ED5FE;
$point05:#EA826A;
$point06:#FF0000;
$point07:#FFC52E;

$gray01:#212121;
$gray02:#414141;
$gray03:#616161;
$gray04:#AAAAAA;
$gray05:#C4C4C4;
$gray06:#D1D1D1;
$gray06_1:#E2E2E2;
$gray07:#E8E8E8;
$gray08:#F1F1F1;
$gray09:#F4F4F4;

$scrim01:#000000;

.cl-basic-black { color: $basic-black; }
.cl-basic-white { color: $basic-white; }

.cl-primary-cyan01 { color: $primary-cyan01; }
.cl-primary-cyan02 { color: $primary-cyan02; }
.cl-primary-cyan03 { color: $primary-cyan03; }
.cl-primary-cyan04 { color: $primary-cyan04; }
.cl-primary-cyan05 { color: $primary-cyan05; }

.cl-secondary01 { color: $secondary01; }
.cl-secondary02 { color: $secondary02; }

.cl-point01 { color: $point01; }
.cl-point02 { color: $point02; }
.cl-point03 { color: $point03; }
.cl-point04 { color: $point04; }
.cl-point05 { color: $point05; }
.cl-point06 { color: $point06; }

.cl-gray01 { color: $gray01; }
.cl-gray02 { color: $gray02; }
.cl-gray03 { color: $gray03; }
.cl-gray04 { color: $gray04; }
.cl-gray05 { color: $gray05; }
.cl-gray06 { color: $gray06; }
.cl-gray06_1 { color: $gray06_1; }
.cl-gray07 { color: $gray07; }
.cl-gray08 { color: $gray08; }
.cl-gray09 { color: $gray09; }

/******* Backgounds *******/
$bg-cl-black:#1A1A1A;
$bg-cl-white:#FFFFFF;
$bg-cl-popup:#F9F9F9;
$bg-cl-gray-1:#212121;
$bg-cl-gray-2:#333333;
$bg-cl-gray-4:#AAAAAA;
$bg-cl-gray-6:#D1D1D1;
$bg-cl-gray-7:#E8E8E8;
$bg-cl-gray-8:#F1F1F1;
$bg-cl-gray-9:#F4F4F4;
$bg-cl-selected-gray:#F1F1F1;
$bg-cl-disabled-1:#D1D1D1;
$bg-cl-disabled-2:#F1F1F1;
$bg-cl-off-bold:#414141;
$bg-cl-off-light:#C4C4C4;
.bg-cl-black { background-color: $bg-cl-black; }
.bg-cl-white { background-color: $bg-cl-white; }
.bg-cl-gray-1 { background-color: $bg-cl-gray-1; }
.bg-cl-gray-2 { background-color: $bg-cl-gray-2; }
.bg-cl-gray-4 { background-color: $bg-cl-gray-4; }
.bg-cl-gray-7 { background-color: $bg-cl-gray-7; }
.bg-cl-gray-8 { background-color: $bg-cl-gray-8; }
.bg-cl-gray-9 { background-color: $bg-cl-gray-9; }
.bg-cl-selected-gray { background-color: $bg-cl-selected-gray; }
.bg-cl-disabled-1 { background-color: $bg-cl-disabled-1; }
.bg-cl-disabled-2 { background-color: $bg-cl-disabled-2; }
.bg-cl-off-bold { background-color: $bg-cl-off-bold; }
.bg-cl-off-light { background-color: $bg-cl-off-light; }


/******* ForSvgs *******/
$svg-cl-white: FFFFFF;
$svg-cl-black: 1A1A1A;
$svg-cl-primary-1: #24CBAA;



