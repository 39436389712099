@use "src/assets/scss/typo";
@include themeify() {
    .board {
        background-color: useVar("bg");
        margin-left: 24px;
        margin-right: 24px;

        .no-board-list {
            width: 26px;
            height: 32px;
        }

        .no-notification-list {
            width: 23px;
            height: 32px;
        }
    }

    .board-item-title{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right-contents{
            margin-left: 8px;
            min-width: 70px;
            text-align: end;
        }
    }

    .board-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 23px;
        padding-top: 23px;
    }

    .board-detail {
        padding-top: 32px;
        padding-bottom: 32px;

        p{
            @include typo.body2-style;
            font-weight: typo.$font-weight-l;
        }

        strong{
            font: revert !important;
        }

        u{
            text-decoration: underline;
        }

        s{
            text-decoration: line-through;
        }

        em{
            font-style: oblique;
        }
    }

    .question-list{
        overflow: scroll;
        max-height: calc(100vh - (63px + 28px + 8px + $header-height));
    }

    .profile-edit-list{
        overflow: scroll;
        max-height: calc(100vh - (43px + 28px + 8px + $header-height));
    }

    .icon {
        .notice-default{
            width: 22px;
            height: 22px;
        }

        .question-complete{
            width: 32px;
            height: 32px;
        }

        .next-s {
            width: 4px;
            height: 8px;
        }

    }

    .faq-list-title{
        width: 100%;
        height: 66px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 0;
        gap: 30px;
    }

    .faq-list-content {
        @include typo.body2-style;
        font-weight: typo.$font-weight-l;

        strong{
            font-weight: typo.$font-weight-m;
        }

        u{
            text-decoration: underline;
        }

        s{
            text-decoration: line-through;
        }

        em{
            font-style: oblique;
        }
    }

    .terms-agree{
        width: 100%;
        height: 62px;
        background-color: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 49px;
        padding: 22px 0;
    }

}