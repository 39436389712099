#VitaIndexDetailView {

  .vitaIndex-button{
    button {
      border-radius: 100px;
      height: 30px;
      margin-right: 8px;
      padding: 6px 12px;
      line-height: 18px;
      font-size: 12px;
      font-weight: $font-weight-m;
      background: $bg-cl-gray-9;
      color: $gray04;

      &.on {
        font-weight: $font-weight-m;
        background: $primary-cyan04;
        color: $primary-cyan02;
      }
    }
  }

  .characteristics-card {
    width: 90%;
    background-color: $basic-white;
    margin-right: 8px;
    padding-top: 22px;
    border-radius: 8px;
  }

  .characteristics-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 35px 24px 22px 24px;
  }

  .raise-score-card {
    width: 90%;
    background-color: $basic-white;
    margin-right: 8px;
    padding: 24px 24px 24px 24px;
    border-radius: 8px;
  }

  .raise-score-chip {
    height: 27px;
    width: fit-content;
    min-width: 46px;
    background-color: $primary-cyan04;
    padding: 3px 10px 2px;
    border-radius: 50px;
    color: $gray03;
  }

  .calender-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;
    margin-bottom: 10px;

    div {
      display: flex;
      text-align: center;
      justify-content: center;
      color: $gray04;
    }
  }

  .grid-content-month {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //background-color: $gray06_1;
      border-radius: 5px;
      height: 44px;
      padding: 4px;

      &.past {
        background-color: $gray07;
      }

      &.today {
        border-radius: 50%;

        .today-inner {
          width: 30px;
          height: 30px;
          background-color: #24CBAA;
          color: #FFFFFF;
          border-radius: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .selected {
          border: 1px solid #24CBAA;
        }
      }

      &.today:after {
        content: '오늘';
        font-size: 12px;
        color: #24CBAA;
        font-style: normal;
        font-weight: 500;
        padding-top: 47px;
        position: absolute;
        //font-size: 12px;
      }

      &.active-day {
        border-radius: 50%;
        .active-day-inner {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .selected {
          border: 1px solid #24CBAA;
        }
      }
    }
  }

  .daily-history {
    background: #F1F1F1;
    border-radius: 8px;

    .daily-history-comment{
      background: #E8E8E8;
      border-radius: 8px;
    }
  }

  .vitaindex-noti {
    position: absolute;
    width: calc(100% - 48px);
  }
}